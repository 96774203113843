import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./forms.css";

const Forms = () => {
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, type, email, message } = formData;

    if (!name || !email || !message || !type) {
      alert("Preencha todos os campos!");
      return;
    }

    try {
      await emailjs.send(
        "service_qcxtoq8",
        "template_rjn0qiq",
        {
          from_name: name,
          message: message,
          type: type,
          email: email,
        },
        "Jx18S61Vs6R18G2pO"
      );
      console.log("Email Enviado");
      setFormData({ name: "", type: "", email: "", message: "" });
      alert("Email enviado com sucesso!");
    } catch (error) {
      console.error("Erro: ", error);
      alert("Erro ao enviar email. Por favor, tente novamente.");
    }
  };

  return (
    <div className="container" id="form-section">
      <h3 className="title">Entre em contato</h3>
      <form className="form" onSubmit={handleSubmit}>
        <input
          className="input"
          type="text"
          placeholder="Digite seu nome"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <input
          className="input"
          type="text"
          placeholder="Você é médico ou paciente?"
          name="type"
          value={formData.type}
          onChange={handleChange}
        />
        <input
          className="input"
          type="email"
          placeholder="Digite seu email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <textarea
          className="textarea"
          placeholder="Digite sua mensagem..."
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        <input className="btnn btn-sec" type="submit" value="Enviar" />
      </form>
    </div>
  );
};

export default Forms;
