import React from "react";
import "./navbar.css";
import Logo from "../../assets/Marcad_água-Icone-verdecannabis.png";

const NavigationBar = () => {
  return (
    <>
      <div className="background-mobile">
        <nav className="navigation-tab" role="navigation">
          <div id="menuToggle">
            <input type="checkbox" />

            <span></span>
            <span></span>
            <span></span>

            <ul id="menu">
              <li>
                <a href="#patologies">Patologias</a>
              </li>
              <li>
                <a href="#Pillars">Etapas</a>
              </li>
              <li>
                <a href="#Services">Serviços</a>
              </li>
              <li>
                <a href="#testimonials">Depoimentos</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <nav className="navbar">
        <div className="logo">
          <img src={Logo} alt="FNOC" />
        </div>
        <ul className="nav-links">
          <li>
            <a href="#patologies">Patologias</a>
          </li>
          <li>
            <a href="#Pillars">Etapas</a>
          </li>
          <li>
            <a href="#Services">Serviços</a>
          </li>
          <li>
            <a href="#testimonials">Depoimentos</a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavigationBar;
