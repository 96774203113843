import React from "react";
import "./pillars.css";
import data from "../data/index.json";

const Pillars = () => {
  return (
    <section id="Pillars" className="pillars--section">
      <div className="p-content">
        <h3 className="p-title">Nossas etapas</h3>
        <div className="p-pillars-box">
          {data?.pillars?.map((item, index) => (
            <div key={index} className="pillars">
              <h3 className="p-pillars-title">{item.title}</h3>
              {item.id === "4" ? (
                <div className="left-align">
                  <p className="p-pillars-description">{item.description}</p>
                  <p className="p-pillars-description">
                    - Garantindo benefícios máximos <br></br>- Estimulando o uso
                    correto e contínuo <br></br>- Monitorando e comunicando
                    eventuais efeitos adversos
                  </p>
                </div>
              ) : (
                <p className="p-pillars-description">{item.description}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pillars;
