import React, { useState, useRef } from "react";
import "./patologies.css";
import data from "../data/index.json";
import left from "../../assets/arrow-left.png";
import right from "../../assets/arrow-right.png";
import autismo from "../data/logo-autismo.png";
import depre from "../data/logo-depre.png";
import dor from "../data/logo-dor.png";
import epilepsia from "../data/logo-epilepsia.png";
import esclerose from "../data/logo-esclerose.png";

const Patologies = () => {
  const [activeCard, setActiveCard] = useState(0);
  const cardCount = data.patologies.length;
  const carouselInnerRef = useRef(null);

  const patologies = [
    {
      id: "1",
      src: epilepsia,
      title: "Epilepsia",
      description:
        "O CBD demonstrou ser eficaz no tratamento de certos tipos de epilepsia, especialmente na Síndrome de Dravet e na Síndrome de Lennox-Gastaut.",
    },
    {
      id: "2",
      src: depre,
      title: "Depressão e Ansiedade",
      description:
        "CBD é estudado para tratar depressão, regulando a serotonina. Também tem efeitos ansiolíticos, úteis para ansiedade, incluindo TAG.",
    },
    {
      id: "3",
      src: dor,
      title: "Dor crônica",
      description:
        "Em relação à dor crônica, o CBD demonstrou propriedades analgésicas e pode contribuir para a redução da dor em pacientes com condições como artrite, fibromialgia e relacionados.",
    },
    {
      id: "4",
      src: autismo,
      title: "Autismo",
      description:
        "O CBD possui propriedades ansiolíticas e antipsicóticas, além de atuar como neuroprotetor. Seu uso em crianças com Transtorno do Espectro Autista (TEA) resulta em melhorias significativas no comportamento, funcionalidade e qualidade de vida, tanto para os pacientes quanto para seus familiares e cuidadores.",
    },
    {
      id: "5",
      src: esclerose,
      title: "Esclerose múltipla",
      description:
        "Para pacientes com esclerose múltipla, o CBD possui propriedades anti-inflamatórias que podem ajudar a diminuir a inflamação no sistema nervoso central, potencialmente aliviando sintomas como espasticidade e dor.",
    },
  ];

  const handlePrev = () => {
    setActiveCard((prevCard) =>
      prevCard === 0 ? cardCount - 1 : prevCard - 1
    );
  };

  const handleNext = () => {
    setActiveCard((prevCard) =>
      prevCard === cardCount - 1 ? 0 : prevCard + 1
    );
  };

  const visibleCards = [
    patologies[(activeCard - 1 + cardCount) % cardCount],
    patologies[activeCard],
    patologies[(activeCard + 1) % cardCount],
  ];

  return (
    <section className="patologies--section" id="patologies">
      <div className="pt-content-boxx">
        <div className="pt-title-container">
          <h3>Quais doenças podem ser tratadas?</h3>
        </div>
        <div className="pt-cards-box">
          <div className="pt-carousel">
            <div className="pt-carousel-inner" ref={carouselInnerRef}>
              {visibleCards?.map((item, index) => (
                <div key={index} className="patologies-card">
                  <div className="patologies-card-img">
                    <img src={item.src} alt={item.title} />
                  </div>
                  <div className="patologies-card-text">
                    <h3 className="pt-card-title">{item.title}</h3>
                    <p className="pt-card-description">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <button className="pt-carousel-prev" onClick={handlePrev}>
              <img src={left} alt="" />
            </button>
            <button className="pt-carousel-next" onClick={handleNext}>
              <img src={right} alt="" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Patologies;
