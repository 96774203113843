import React, { useState, useRef } from "react";
import "./testimonials.css";
import data from "../data/index.json";
import testimonial from "../../assets/testimonial-icon.png";

const Patologies = () => {
  const [activeCard, setActiveCard] = useState(0);
  const cardCount = data.testimonials.length;
  const carouselInnerRef = useRef(null);

  const handlePrev = () => {
    setActiveCard((prevCard) =>
      prevCard === 0 ? cardCount - 3 : prevCard - 1
    );
  };

  const handleNext = () => {
    setActiveCard((prevCard) =>
      prevCard === cardCount - 3 ? 0 : prevCard + 1
    );
  };

  const visibleCards = data.testimonials.slice(activeCard, activeCard + 3);

  return (
    <section className="testimonials--section" id="testimonials">
      <div className="pt-content-box">
        <div className="pt-title-containerr">
          <h3>Depoimentos de nossos clientes</h3>
        </div>
        <div className="pt-cards-box">
          <div className="pt-carousel">
            <div className="tt-carousel-inner" ref={carouselInnerRef}>
              {visibleCards.map((item, index) => (
                <div key={index} className="testimonials-card">
                  <div className="testimonials-card-img">
                    <img src={testimonial} alt="" />
                  </div>
                  <p className="pt-card-local"> - {item.author}</p>
                  <p className="pt-card-testimonial">{item.testimonial}</p>
                  <p className="pt-card-author">{item.local}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Patologies;
