import React from "react";
import "./footer.css";
import Logo from "../../assets/Marcad_água-Icone-verdecannabis.png";
import Insta from "../../assets/icon-insta.svg";
import Facebook from "../../assets/icon-facebook.svg";

const Footer = () => {
  return (
    <section id="Footer" className="footer--section">
      {/* <div className="ft-content">
        <div className="left-ft">
          <div className="ft-img">
            <img className="img-fnoc" src={Logo} alt="" />
          </div>
          <ul className="ft-ul">
            <li className="ft-li">
              <a
                target="_blank"
                href="https://www.instagram.com/fullnaturecannabisoficial/"
                rel="noreferrer"
              >
                <img className="li-img-insta" src={Insta} alt="" />
              </a>
            </li>
            <li className="ft-li">
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100087895210781"
                rel="noreferrer"
              >
                <img className="li-img-face" src={Facebook} alt="" />
              </a>
            </li>
          </ul>
        </div>
        <div className="align-mobile">
          <p className="ft-copyright">© 2024 Full Nature Of Cannabis</p>
          <div className="dev-credits">
            Feito por{" "}
            <a
              target="_blank"
              href="https://github.com/Jvmntr"
              rel="noreferrer"
            >
              <button className="btn btn-primary">Novatech</button>
            </a>
          </div>
        </div>
      </div> */}
      <div className="ft-content">
        <div className="ft-img">
          <img className="img-fnoc" src={Logo} alt="" />
        </div>
        <div className="image-links">
          <ul className="ft-ul">
            <li className="ft-li">
              <a
                target="_blank"
                href="https://www.instagram.com/fullnaturecannabisoficial/"
                rel="noreferrer"
              >
                <img className="li-img-insta" src={Insta} alt="" />
              </a>
            </li>
            <li className="ft-li">
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100087895210781"
                rel="noreferrer"
              >
                <img className="li-img-face" src={Facebook} alt="" />
              </a>
            </li>
          </ul>
        </div>
        <p className="ft-copyright">© 2024 Full Nature Of Cannabis</p>
        <div className="dev-credits">
          Feito por{" "}
          <a target="_blank" href="https://github.com/Jvmntr" rel="noreferrer">
            <button className="btnf btn-primary">Novatech</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
