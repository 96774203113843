import React from "react";
import "./heroSection.css";
import Background from "../../assets/background-content-img.svg";

const HeroSection = () => {
  return (
    <section id="HeroSection" className="hero--section">
      <div class="background-content-desktop">
        <img class="background-image" src={Background} alt="" />
        <div className="hs-content-box">
          <p className="hs-title">
            Sua jornada de tratamento{" "}
            <span class="hs-text-title-deco">em um toque!</span>
          </p>
          <p className="hs-description">
            Transforme sua vida com o Canabidiol! Experimente o poder da
            homeostase e alívio para seu corpo. Adquira agora e comece sua
            jornada rumo ao bem-estar! Consulte um médico para orientação
            personalizada.
          </p>
          <a href="#form-section">
            <button className="btn btn-primary">Comece seu tratamento!</button>
          </a>
        </div>
      </div>
      <div class="background-content-mobile">
        <div className="hs-content-box">
          <p className="hs-title">
            Sua jornada de tratamento{" "}
            <span class="hs-text-title-deco">em um toque!</span>
          </p>
          <p className="hs-description">
            Transforme sua vida com o Canabidiol! Experimente o poder da
            homeostase e alívio para seu corpo. Adquira agora e comece sua
            jornada rumo ao bem-estar! Consulte um médico para orientação
            personalizada.
          </p>
          <a className="smooth" href="#form-section">
            <button className="btn btn-primary">Comece seu tratamento!</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
