import './App.css';
import HeroSection from './components/HeroSection/heroSection';
import NavigationBar from './components/Header/navbar';
import Patologies from './components/Patologies/patologies';
import Pillars from './components/Pillars/pillars';
import Services from './components/Services/services';
import Testimonials from './components/Testimonials/testimonials';
import Forms from './components/Form/forms';
import Footer from './components/Footer/footer';

function App() {
  return (
    <div style={{
      padding: "0",
      margin: "0",
      height: "100%",
      width: "102%",
      background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(196,235,192,1) 50%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 80%, rgba(196,235,192,1) 100%)",
      zIndex: -1,
    }}>
      <NavigationBar />
      <HeroSection />
      <Patologies />
      <Pillars />
      <Services />
      <Testimonials />
      <Forms />
      <Footer />
    </div>
  );
}

export default App;
