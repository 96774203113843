import React, { useState } from "react";
import "./services.css";
import turn from "../../assets/arrow-turn.png";
import serviceM from "../../assets/serviceM.png";
import serviceP from "../../assets/serviceP.png";

const Services = () => {
  const [activeService, setActiveService] = useState("s-content-f"); // Serviço inicial ativo

  const handleClick = (serviceName) => {
    setActiveService(serviceName === activeService ? "" : serviceName); // Alterna visibilidade
  };

  return (
    <section id="Services" className="services--section">
      <div
        className={`s-content ${
          activeService === "s-content-f" ? "visible" : ""
        }`}
      >
        <h3 className="s-title">Nossos serviços</h3>
        <div className="services-content-box">
          <div className="servies-content-left">
            <h3 className="service-name-title">Médico</h3>
            <p className="service-description">
              Profissionais de saúde: Médicos e Odontólogos devidamente
              registrados em seus respectivos conselhos , têm autorização para
              prescrever produtos derivados da Cannabis.
            </p>
          </div>
          <div className="services-content-right">
            <div className="service-image">
              <img src={serviceM} alt="" />
            </div>
            <a href="#form-section">
              <button className="bbtn btn-secondary">
                Faça parte da equipe de profissionais!
              </button>
            </a>
          </div>
        </div>
        <div className="content-box">
          <div
            className="change-service-button"
            onClick={() => handleClick("s-content-s")}
          >
            <img src={turn} alt="" />
          </div>
        </div>
      </div>

      <div
        className={`s-content ${
          activeService === "s-content-s" ? "visible" : ""
        }`}
      >
        <h3 className="s-title">Nossos serviços</h3>
        <div className="services-content-box">
          <div className="servies-content-left">
            <h3 className="service-name-title">Paciente</h3>
            <p className="service-description">
              Qualquer pessoa que sofre de tais patologias que podem ser tratado
              com produtos derivados da cannabis, devidamente acompanhados por
              um médico e com receita, podem se tornar pacientes e se beneficiar
              da terapia canabinóide
            </p>
          </div>
          <div className="services-content-right">
            <div className="service-image">
              <img src={serviceP} alt="" />
            </div>
            <a href="#form-section">
              <button className="bbtn btn-secondary">
                Comece seu tratamento!
              </button>
            </a>
          </div>
        </div>
        <div className="content-box">
          <div
            className="change-service-button"
            onClick={() => handleClick("s-content-f")}
          >
            <img src={turn} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
